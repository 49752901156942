import React, { useState } from "react";
import ReactDOM from "react-dom";
import InvSelRows from "../pages/InvSelect/InvSelRows";
import InvSelect from '../pages/InvSelect/InvSelect'

    function App() {
        const [monthYear, setMonthYear] = useState('');
        const [idCliente, setIdCliente] = useState(0);
        const [fattInfo, setFattInfo] = useState({});

        const handleMonthYear = (newValue) => {
            setMonthYear(newValue);
        };

        const handleIdCliente = (newValue) => {
            setIdCliente(newValue);
        };

        const handleFattInfo = (newValue) => {
            setFattInfo(newValue);
        };

        return (
                <div className ="App">
                    {(idCliente !== 0 && 
                            monthYear !== '' && 
                            Object.keys(fattInfo).length !== 0) ? 
                        <InvSelRows
                            monthYear = { monthYear }
                            idCliente = { idCliente }
                            fattInfo={ fattInfo }
                            setParentFattInfo={ handleFattInfo }
                        /> 
                    :
                        <InvSelect
                            monthYear = { monthYear }
                            idCliente = { idCliente }
                            setParentMonthYear={ handleMonthYear }
                            setParentIdCliente={ handleIdCliente }
                            setParentFattInfo={ handleFattInfo }
                        />
                    } 
                </div>
                );
    }

export default App;
