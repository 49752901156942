import React, { Component } from 'react';

class MyCellRenderer extends Component {
    constructor(props) {
        super(props);
    };
    
    render() {
    return (
        <React.Fragment>
        <b>
        <button type="button">
            {this.props.value}
        </button>

        <a href="url">
            {this.props.value}
        </a>
        </b>
      </React.Fragment>
    );
    }
  }

export default MyCellRenderer;
