import React, { Component } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import MyCellRenderer from './MyCellRenderer.jsx';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import './Invoices.css';
import '../Navbar/Navbar.css';

class InvJobList extends Component {
    constructor(props) {
        const { setParentFattInfo } = props;
        super(props);

        this.state = {
            columnDefs: [
                {   field: "idProgetto",
                    hide: true},
                {   field: "NomeProgetto",
                    hide: true},
                {   field: "idRichiesta",
                    hide: true},
                {   field: "idPersonale",
                    hide: true},
                {   field: "YM",
                    hide: true},
                {   field: "idFattura",
                    hide: true},
                {   field: "Progetto",
                    headerName: "Progetto",
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "Supervisor",
                    headerName: "Supervisor",
                    sortable: true,
                    filter: true,
//                    cellRendererFramework: MyCellRenderer,
                    cellClass: "vertical-middle"},
                {   field: "Descrizione",
                    headerName: "Qualifica",
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "Importo",
                    headerName: "Importo",
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "Storno",
                    headerName: "Costo",
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
            ],
            rowData : [
                
            ],
            frameworkComponents: {

            },
            msg: 'base',
         };
    };
    
    componentDidMount() {
    };

    changeMsg = (newValue, newYM) => {
        var requestOptions = '';
        var callUrl = '';
        requestOptions = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
               },
            body: JSON.stringify({   
                    idCliente : newValue,
                    monthYear : newYM})
        };
        callUrl = process.env.REACT_APP_API_URL+'fatture/listjobs';
        fetch(callUrl, requestOptions)
          .then(response => response.json())
          .then(rowData => this.setState({ rowData }));
    };
    
    render() {
    return (
      <div className="Invoices">
        <div className="ag-theme-alpine ag-style">
          <AgGridReact
            defaultColDef={{ flex: 1 }}
            rowHeight={60}
            frameworkComponents={this.state.frameworkComponents}
            onRowClicked={(e) => {
                var requestOptions = '';
                var callUrl = '';
                requestOptions = {
                    method: 'POST',
                    headers: {
                       'Content-Type': 'application/json',
                       'Accept': 'application/json'
                       },
                    body: JSON.stringify(e.data)
                };
                callUrl = process.env.REACT_APP_API_URL+'fatture/findornew';
                fetch(callUrl, requestOptions)
                  .then(response => response.json())
                  .then((rowData) => {
                        delete rowData.debug;
                        var infoOut = {
                            fattNum : rowData.idFattura,
                            fattYM : e.data.YM,
                            fattProgetto : e.data.Progetto,
                            fattNomeProgetto : e.data.NomeProgetto,
                            fattSupervisor : e.data.Supervisor,
                            fattQualifica : e.data.Descrizione,
                            fattTitle : ' Order '+
                                e.data.Progetto + ' ' +
                                e.data.Supervisor + ' ' +
                                e.data.YM,
                            fattData : rowData
                        };
                        this.setState({ rowdata: rowData });
                        this.props.setParentFattInfo(infoOut);
                    });
                } }
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}>
          </AgGridReact>
        </div>
      </div>
    );
    }
  }

export default InvJobList;
