import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Formik, Field, Form, useFormik, useFormikContext } from "formik";
import '../Navbar/Navbar.css';
import SelectField from '../../components/SelectField.js';
import InvJobList from './InvJobList'
//import RowFormTest from '../../components/RowFormTest'


class InvSelect extends Component {

    constructor(props) {
        const { setParentMonthYear } = props;
        const { setParentIdCliente } = props;
        const { setParentFattInfo } = props;
        const { monthYear } = props;
        const { idCliente } = props;
        super(props);
        this.msgElement = React.createRef();
        this.updListElement = React.createRef();
        this.handleChangeMonthYear = this.handleChangeMonthYear.bind(this);
    };
    
    state = {
        loading: true,
        id: window.location.href.split("/").pop(),
        invData: {
            monthYear: this.props.monthYear,
            idCliente: this.props.idCliente},
        showTable: false,
        monthYearOptions: null,
        clientOptions: null,
        successAlertVisible: false,
        errorAlertVisible: false,
        errorAlertMessage: '',
        msg: '',
    }
    
    componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + 'fatture/listclienti')
    .then(res => res.json())
    .then((clienti) => {
        const clientOptions = clienti.map(cliente => {
            return { value: cliente.idClienteFin, label: cliente.RagSoc };
        });
        this.setState({
            clientOptions: clientOptions,
        });
    });

    fetch(process.env.REACT_APP_API_URL + 'fatture/monthyears')
    .then(res => res.json())
    .then((monthYear) => {
        const monthYearOptions = monthYear.map(monthYear => {
            return { value: monthYear.Key, label: monthYear.Value };
        });
        this.setState({
            monthYearOptions: monthYearOptions,
        });
    });
    
    this.setState({ loading: false });

    if(this.props.monthYear != '' && this.props.idCliente > 0) {
        this.updListElement.current.changeMsg(this.props.idCliente, this.props.monthYear);
    };

    };
    
    handleChangeClient = (Client) =>{
        this.setState({msg: Client})
        this.msgElement.current.changeMsg(Client);
    };
    
    handleChangeMonthYear(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    };

    saveSelection = (invData) => {
        this.setState({msg: invData.idCliente});
        this.updListElement.current.changeMsg(invData.idCliente, invData.monthYear);
        this.props.setParentMonthYear(invData.monthYear);
        this.props.setParentIdCliente(invData.idCliente);
    }

    render() {    
    const { supplier } = this.state;
    return (
      <div>
        <div>
            {this.props.test}
        </div>
        <Formik
            initialValues={ this.state.invData }
            onSubmit={(values, { setSubmitting }) => {
              this.saveSelection(values);
              setSubmitting(false);
            }}
        >
        { ( {
              touched,
              errors,
            } ) => (
                    
          <Form
            >
            <table>
                <tbody>
                    <tr>
                        <td>
                            <label htmlFor="monthYear">Mese - Anno</label>
                        </td>
                        <td>
                            <Field
                                component={SelectField}
                                name="monthYear"
                                onChange={this.handleChangeMonthYear}
                                options={this.state.monthYearOptions ? 
                                    this.state.monthYearOptions : 
                                    [{value: '', label: 'No Options'}]}
                                isClearable={true}
                                />
                                {errors.required && touched.required ? (
                                    <div className="invalid-tooltip mt-25">{errors.required}</div>
                                ) : null}
                        </td>
                        <td>
                            <label htmlFor="idCliente">Cliente</label>
                        </td>
                        <td>
                            <Field
                                component={SelectField}
                                name="idCliente"
                                onChange={(value) => this.handleChangeClient(value)}
                                options={this.state.clientOptions ? 
                                    this.state.clientOptions : 
                                    [{value: '', label: 'No Options'}]}
                                isClearable={true}
                                />
                                {errors.required && touched.required ? (
                                    <div className="invalid-tooltip mt-25">{errors.required}</div>
                                ) : null}
                        </td>
                        <td>
                            <button type="submit">Submit</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
          </Form>
          )} 
          
        </Formik>
        <InvJobList 
            ref={this.updListElement}
            monthYear = {this.props.monthYear}
            idCliente = {this.props.idCliente}
            setParentFattInfo = {this.props.setParentFattInfo}
            />
      </div>
      );
    };
    
    }

export default InvSelect;