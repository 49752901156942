import React, { Component } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import './InvSelRows.css';

class InvSelRows extends Component {
    
    
    constructor(props) {
        const { fattInfo } = props;
        const { monthYear } = props;
        const { idCliente } = props;
        const { setParentFattInfo } = props;
        super(props);
        
        this.gridRef = React.createRef();
        this.popupRef = React.createRef();
        const closePopup = () => this.popupRef.current.close();
        const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0);
        const giorniMese = ym => {
            const arrYM = ym.split('-');
            return new Date(arrYM[0], arrYM[1], 0).getDate();
        };
                
        this.state = {
            columnDefs: [
                {   field: "idVociFattura",
                    headerName: "id",
                    hide: true},
                {   field: "idFattura",
                    headerName: "Fattura",
                    hide: true},
                {   field: "Voce",
                    headerName: "Voce",
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "UniMisura",
                    headerName: "Unità di misura",
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "Numero",
                    headerName: "Qnt.",
                    editable: true,
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "RigaValUni",
                    headerName: "Val. Fatt.",
                    editable: true,
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "TotNumVal",
                    headerName: "Fatturato",
                    valueGetter: 'data.Numero * data.RigaValUni ?? 0',
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "NumStorno",
                    headerName: "Qnt. Costi",
                    editable: true,
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "ValStorno",
                    headerName: "Costo un.",
                    editable: true,
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "Storno",
                    headerName: "Costo",
                    valueGetter: 'data.NumStorno * data.ValStorno ?? 0',
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
                {   field: "Note",
                    headerName: "Note",
                    editable: true,
                    sortable: true,
                    filter: true,
                    cellClass: "vertical-middle"},
            ],
            rowData : null,
            fattId : this.props.fattInfo.fattData.idFattura,
            nomeExcelFile : this.props.fattInfo.fattYM+'-Fatt-'+
                this.props.fattInfo.fattData.idFattura.toString()+'-'+
                this.props.fattInfo.fattSupervisor+'-'+
                this.props.fattInfo.fattQualifica
                    .replace(/[^a-z0-9]/gi, '_')
                    .toLowerCase(),
            showPopup : false,
            giorni: 0,
            giorniMese: giorniMese(this.props.fattInfo.fattYM)
            
        };
    };
   
    componentDidMount() {
//    const id=window.location.href.split("/").pop();
//    this.setState({ fattId : id});
//    this.setState({ giorni : this.onCellValueChanged({})});
    fetch(process.env.REACT_APP_API_URL+'fattrighe/listfattrighe/'+
            this.props.fattInfo.fattData.idFattura.toString())
      .then(result => result.json())
      .then(fattRowsData => {
            this.setState({ rowData: fattRowsData });
            this.setState({ giorni : this.calcGG(fattRowsData)});
            });
    };
        
    back = (e) => {
        e.preventDefault();
        this.props.setParentFattInfo({});
        };
    
    saveDetail = (e) => {
        e.preventDefault();
        var requestOptions = '';
        var callUrl = '';
        requestOptions = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
               },
            body: JSON.stringify({fattId: this.state.fattId,
                rowsData: this.state.rowData})
        };
        callUrl = process.env.REACT_APP_API_URL+'fattrighe/saveinvrows';
        fetch(callUrl, requestOptions)
          .then(response => response.json())
          .then((rowData) => {
                this.props.setParentFattInfo({});
                });
        };
    

    saveExcelFatt = (e) => {
        e.preventDefault();
        var requestOptionsExcel = '';
        var callUrlExcel = '';
        requestOptionsExcel = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
               },
            body: JSON.stringify({fattId: this.props.fattInfo.fattNum,
                progetto: this.props.fattInfo.fattProgetto,
                supervisor: this.props.fattInfo.fattSupervisor,
                qualifica: this.props.fattInfo.fattQualifica,
                titolo: "Fatt. "+this.props.fattInfo.fattTitle,
                ym: this.props.fattInfo.fattYM,
                nomeFile: this.state.nomeExcelFile,
                rowsData: this.state.rowData,
                mode: "fatt"})
        };
        callUrlExcel = process.env.REACT_APP_API_URL+'fatture/outexcelrows';
        fetch(callUrlExcel, requestOptionsExcel)
          .then(response => response.json())
          .then((rowData) => {
                alert('Ok');
                });
        };

    saveExcelCosts = (e) => {
        e.preventDefault();
        var requestOptionsExcel = '';
        var callUrlExcel = '';
        requestOptionsExcel = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
               },
            body: JSON.stringify({fattId: this.props.fattInfo.fattNum,
                progetto: this.props.fattInfo.fattProgetto,
                supervisor: this.props.fattInfo.fattSupervisor,
                qualifica: this.props.fattInfo.fattQualifica,
                titolo: "Costs "+this.props.fattInfo.fattTitle,
                ym: this.props.fattInfo.fattYM,
                nomeFile: this.state.nomeExcelFile,
                rowsData: this.state.rowData,
                mode: "costs"})
        };
        callUrlExcel = process.env.REACT_APP_API_URL+'fatture/outexcelrows';
        fetch(callUrlExcel, requestOptionsExcel)
          .then(response => response.json())
          .then((rowData) => {
                alert('Ok');
                });
        };

    saveSSM = (e) => {
        e.preventDefault();
        const requestOptionsSSM = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
               },
            body: JSON.stringify({
                fattId: this.state.fattId,
                rowsData: this.state.rowData
            })
        };
        const callUrlSSM = process.env.REACT_APP_API_URL+'fatture/outssmrows';
        fetch(callUrlSSM, requestOptionsSSM)
          .then(response => response.json())
          .then((rowData) => {
                alert('Ok');
                });
        this.popupRef.current.close();
        };
        
    showPopup = (e) => {
        e.preventDefault();
        this.setState({showPopup: !this.state.showPopup}); 
        return false;
    };    

    onCellValueChanged = ({ data }) => {
        // console.log(data);
        var giorni = 0;
        this.gridRef.current.gridOptions.api.forEachNode((rowNode, index) => {
            giorni += rowNode.data.UniMisura === 'GG' ? 
                parseInt(rowNode.data.Numero) : 0;
//            console.log(
//                    'Riga '+index+
//                    ' UniMisura '+rowNode.data.UniMisura+
//                    ' Giorni '+rowNode.data.Numero);            
        });
        this.setState({ giorni : giorni});
    };

    calcGG = (data) => {
        var giorni = 0;
        data.forEach((row, index) => {
            giorni += row.UniMisura === 'GG' ? 
                parseInt(row.Numero) : 0;
//            console.log(
//                    'Riga '+index+
//                    ' UniMisura '+row.UniMisura+
//                    ' Giorni '+row.Numero);            
        });
        return giorni;
    };

        
    render() {
    return (
      <div className="InvRows">
        <h2>
            Invoice Number {sessionStorage.getItem('fattNum')}
            {sessionStorage.getItem('fattTitle')}
        </h2>
        <div style={{width: '100%', display: 'flex'}}>
            <div style={{flex: 1, width: '60%'}}>
            <ul>
                <li>
                    <a href="/invrowform/0" onClick={this.saveDetail}> Save </a>
                </li>
                <li>
                    <Popup 
                            trigger={<a href="#"> Excel Fatt.</a>}
                            position="right center"
                            ref={this.popupRef}
                            onOpen={this.saveExcelFatt} >
                        <a href={process.env.REACT_APP_FILE_URL+
                                        this.state.nomeExcelFile+".xlsx"} 
                                onClick={this.closePopup}> Save & <br/> Download </a>
                    </Popup>
                </li>
                <li>
                    <Popup 
                            trigger={<a href="#"> Excel Costs</a>}
                            position="right center"
                            ref={this.popupRef}
                            onOpen={this.saveExcelCosts} >
                        <a href={process.env.REACT_APP_FILE_URL+
                                        this.state.nomeExcelFile+".xlsx"} 
                                onClick={this.closePopup}> Save & <br/> Download </a>
                    </Popup>
                </li>
                <li>
                    <Popup 
                            trigger={<a href="#"> SSM </a>}
                            position="right center"
                            ref={this.popupRef} >
                        <a href={'#'} 
                                onClick={this.saveSSM}> Save & <br/> Update SSM </a>
                    </Popup>
                </li>
                <li className="last-menu-item">
                    <a href="#" onClick={this.back}> Back </a>
                </li>
            </ul>
        </div>
            <div style={{marginLeft: 'auto', padding: '26px'}}>
                Giorni {this.state.giorni}/{this.state.giorniMese}
            </div>
            </div>
        <div className="ag-theme-alpine ag-style">
          <AgGridReact
            ref={this.gridRef}
            defaultColDef={{ flex: 1 }}
            rowHeight={60}
            onCellValueChanged={this.onCellValueChanged}
            columnDefs={this.state.columnDefs}
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}>
          </AgGridReact>
        </div>
      </div>

    );
    }
  }

export default InvSelRows;


//            onRowClicked={(e) => window.location.replace(
//                    process.env.REACT_APP_URL+"invrowform/"+e.data.idRigaFattura)}

//        {this.state.showPopup ? 
//            : null
//        }