import React from 'react';
import Select from 'react-select';
import {useField} from 'formik';

function SelectField(props) {
  // console.log(props);
  const [field, { value }, { setValue, setTouched }] = useField(props.field.name);
  const options = props.options ? props.options : [];
  const onChange = (value) => {
    // console.log(value);
    var newValue = value ? value.value : 0
    // console.log(newValue);
    setValue(newValue);
  };
  return (
    <Select
      {...props}
      value={options ? options.find(option => option.value === field.value) : ''}
      onChange={onChange}
      onBlur={setTouched}
    />
  );
}

export default SelectField;
